.App{
    display: flex;
    overflow-x: hidden;
    flex-direction: column;
}

a{
    color: #ffb834;
    text-decoration: none;
}

.purchaseButton{
    background-color: #ffb834;
    color: black;
    padding: 10px 30px;
    font-weight: 700;
    border-radius: 5px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    margin: 40px 0px;
    cursor: pointer;
}

.photos{
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 20px;
 
}.photos img{
    width: 100%;
    height: 300px;
    object-position: bottom;
    object-fit: cover;
}

.shades{
    position: absolute;
    background-color: black;
    height: 170px;
    width: 100%;
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    bottom: 0;
}

.neg{
    top: 0;
    bottom: unset !important;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);

}

.errorPage{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: white;
    width: 100%;
    height: 100vh;
}

.notification{
    background-color: rgb(243, 207, 0);
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    text-align: center;
    z-index: 2000;
    justify-content: center;
    align-items: center;
    display: flex;
    font-weight: 600;
    color: black;
    transform: translateY(-100%);
    transition: .2s;
    font-weight: 600;
    opacity: 0;
}

.notification.opened{
    transform: translateY(0%);
    opacity: 1;
}   


.fixed-section{
    height: 100vh;
    position: fixed;
    top:0px;
    width: 400px;
    overflow: hidden;
}

#john-image{
    object-fit: contain;
    margin-top: 50px;
    height: 100%;
    width: 100%;
    object-position: bottom;
}

.bottom-overlay{
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
    height: 100%;
    width: 100%;
    z-index: 4;
    position: absolute;
    top: 0;
    pointer-events: none
}
.main-section-con{
    /* margin-left: auto; */
    margin-bottom: 100px;
    /* width: 75%; */
    z-index: 200;
}
.main-section{
   margin: auto;
   width: -moz-fit-content;
   width: fit-content;
   text-align: center;
}

.fancy-font{
    font-family: 'Amatic SC', cursive;
}

.title-con{
    font-size: 90px;
    line-height: 100px;
    margin-top: 100px;
    position: relative;
}
.title-con img{
    left: -60px;
    top: -50px;
    height: 80px;
    position: absolute;
    opacity: .4;
}
.title-con .fancy-font{
    color: #eeeeee;
}
.inverted-image{
    right: -60px !important;
    left: unset !important;
    transform: rotateY(180deg);
}

.sub-title{
    letter-spacing: 5px;
    font-size: 20px;
    color: #cecece;
}

.song-card-con{
    margin: auto;
    margin-top: 50px;
    padding: 25px;
    max-width: 500px;
}

.single-song-card, .single-song-card > div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    gap: 15px;
    font-weight: 300;
}
.single-song-card{
    margin-bottom: 25px;
}
.pause-and-play{
    height: 45px;
    width: 45px;
    cursor: pointer;
}
.dim{
    opacity: .3;
    transition: .2s;
}
.dim:hover{
    opacity: 1;
}
.song-title{
    cursor: pointer;
    font-size: 18px;
    margin-bottom: 3px;
    white-space: nowrap; 
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis; 
}

.song-title:hover{
    text-decoration:underline;
    text-decoration-color: #ffffff6b;
}

.song-details{
    font-size: 14px;
    opacity: .4;
}

.download-btn{
    height: 15px;
    width: 15px;
    opacity: .4;
}

.score-parts{
    display: flex;
    gap: 10px;
    font-size: 14px;
    background-color: #ffffff10;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: .2s;
    font-size: 14px;

}

.score-parts:hover{
    background-color: #ffffff25;
}
.whoisJohn{
    margin: auto;
    margin-top: 60px;
    width: 80%;
}
.sub-heading{
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px;
    color: #ffb834 ;
    letter-spacing: 3px;

}

.whoisJohn p{
    font-weight: 300;
    font-size: 15px;
    line-height: 28px;
    opacity: .8;
    margin-bottom: 20px;
    text-align: justify;
}

.social-links{
    position: absolute;
    bottom: 60px;
    z-index: 20;
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    align-items: center;
}
.social-links img{
    height: 35px;
    cursor: pointer;
    transition: .2s;
    opacity: 1;
}#buycoffee{
    height: 40px;
}#facebook{
    height: 41px;
}
.social-links img:hover{
    transform: translateY(2px);
}
.stayConnected{
    margin-top: 80px;
}
.contact-form {
    max-width: 650px;
    margin: auto;
}
.contact-form input:not(.submit-button), .contact-form textarea{
    outline: none;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.226);
    width: 100%;
    margin-bottom: 15px;
    padding: 10px 15px;
    border-radius: 8px;
    margin-top: 8px;
    padding-top: 25px;
}.contact-form label{
    font-size: 12px;
    font-weight: 300;
    border-radius: 8px;
    padding: 3px 10px;
    margin-left: 10px;
    position: absolute;
    background-color: rgb(0, 0, 0);
    color: rgba(255, 255, 255, 0.411);
    border: 1px solid rgba(255, 255, 255, 0.226);
}
.error{
    font-size: 13px;
    text-align: center;
    margin-top: -12px;
    margin-bottom: 10px;
    color: rgb(228, 47, 47);
    height:0px;
    overflow: hidden;
    transition: .2s;
    opacity: 0;
}

.error.opened{
    height: 25px;
    opacity: 1;
}

.contact-form input::placeholder, .contact-form textarea::placeholder{
    opacity: .5;
}

.name-and-mail{
    display: flex;
    gap: 15px;
}
.name-and-mail div{
    width: 100%;
}

.contact-form textarea{
    min-height: 150px;
}

.submit-button{
    color: black;
    font-weight: 600;
    background-color: white;
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    opacity: .9;
    transition: .2s;
    align-self: flex-end;
    
}

.submit-button:hover{
    transform: translateY(2px);
    opacity: 1;
}

.pop-up{
    width: 100%;
    height: 100%;
    background-color: rgb(14, 14, 14);
    z-index: 200;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
}

.download-box{
    margin: 30px;
    background-color: black;
    max-width: 680px;
    width: -webkit-fill-available;
    width: stretch;
    width: fill;
    min-height: 450px;
    border-radius: 18px;
    margin-top: 100px;
    position: relative;
    padding-bottom: 30px;
}

.top-download-box{
    background-color: white;
    padding: 30px;
    width: 100%;
    min-height: 350px;
    border-radius: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}


.download-box-pup-con *{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: black !important;
}.download-box-pup-con h1{
    max-width: 350px;
    text-align: center;
}

.dogImage {
    height: 100px;
}

.download-box-pup-con div{
    color: black;
    font-size: 40px;
    margin-left: -20px;
}

.buymeacoffee{
    width: 150px;
    cursor: pointer;
    transition: .2s;
}.buymeacoffee:hover{
    transform: translateY(2px);
}


.download-input-box{
    position: relative;
    display: flex;
    align-items: center;
}
.payment-form{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    max-width: 750px;
    align-items: center;
}
.down-input, .email-address{
    border-radius: 14px;
    padding: 10px 12px;
    padding-right: 10px !important;
    font-size: 15px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.466);
    background-color: transparent;
    color: black;
    min-width: 300px;
    width: 100%;
}
.down-input::placeholder{
    color: black;
}
.download-input-box div{
    color: black;
    position: absolute;
    padding-left: 18px;

}

.pup-download-btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
}
.disabled{
    background-color: rgb(177, 177, 177) !important;
    color: rgb(233, 233, 233);
    cursor: not-allowed !important;
}.red{
    border-color: rgb(184, 1, 1);
    color: rgb(184, 1, 1);
    font-size: 13px;
}
.pup-download-btn{
    background-color: #01398f ;
    padding: 12px 14px;
    border-radius: 14px;
    font-size: 16px;
    cursor: pointer;
    z-index: 100;
    transition: .2s;
    display: flex;
    gap: 5px;
    width: 100%;
    justify-content: space-between;
}.pup-download-btn:hover:not(.disabled){
    transform: translateX(2px);
    background-color: #0048a7 ;
}

.download-text{
    font-weight: 300;
    text-align: center;
    margin-top: 20px;
}

.lyc-close-icon,.close-icon{
    height: 25px;
    width: 25px;
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 4px;
    border: 1px solid black;
    border-radius: 50%;
    cursor: pointer;
    opacity: .7;
    transition: .2;
}

.close-icon:hover{
    opacity: 1;
}

.mobile-hi{
    display: none;
    font-size: 200px;
    position: fixed;
    left: 70px;
    transform: rotateZ(-10deg);
    z-index: -1;
}

.darkCenter{
    margin: auto;
    height: 30px !important;
    width: 30px !important;
    border-top: 1px solid rgb(0, 0, 0) !important;
    border-right: 1px solid rgba(0, 0, 0, 1) !important;
    border-left: 1px solid rgba(0, 0, 0,1) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
}
.inline-loading{
    border-radius: 50%;
    height: 20px;
    width:20px;
    animation: rotate 1s infinite linear;
    border-top: 1px solid rgb(255, 255, 255);
    border-right: 1px solid rgba(255, 255, 255, 0.705);
    border-left: 1px solid rgba(255, 255, 255, 0.747);
    border-bottom:.5px solid rgba(255, 255, 255, 0.658);
}.loadingPage{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: black;
    display: flex;
    align-items: center;
    z-index: 10000;
    justify-content: center;
}.loadingPage p{
    font-size: 13px;
    font-weight: 100;
    opacity: .3;
    margin-top: 80px;
}
.small-loading{
    border-radius: 50%;
    height: 30px;
    width:30px;
    border-top: 1px solid rgba(255, 255, 255, 0.295);
    border-right: 1px solid rgba(255, 255, 255, 0.295);
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-bottom:.5px solid rgba(255, 255, 255, 0.295);
    position: absolute;
    margin: auto;
    animation: rotate 1s infinite linear;
}.center{
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.download-page{
    text-align: center;
    padding: 20px;
    padding-top: 50px;
}
.download-page p{
    margin-top: 20px;
    opacity: .7;
    font-weight: 100;
}
.download-page h1{
    font-size: 50px;
}

@keyframes rotate{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

.flutter-pay{
    background-color: #e9830e;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    min-width: 300px;
    width: 100%;
}

.lyrics{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:  25px;
}

.lyricsObj{
    margin-top: 30px;
}

.lyrics > h1{
    font-size: 20px;
    text-align: center;
    margin-top: 30px;
    font-weight: 100;
}.lyrics > p{
    opacity: .5;
    font-weight: 100;
}
.main-lyrics{
    margin-bottom: 20px;
}
.main-lyrics p{
    opacity: .3;
    font-size: 14px;
    font-weight: 100;
}

.main-lyrics pre{
    margin-top: 5px;
    font-weight: 100;
}
.lyc-close-icon{
    background-color: #fafafa;
}

.purchaseAndPreview{
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.purchaseAndPreview div, .purchaseAndPreview a{
    display: flex;
    gap: 10px;
    font-size: 14px;
    background-color: #ffffff10;
    font-weight: 600;
    border-radius: 5px;
    padding: 10px 15px;
    cursor: pointer;
    transition: .2s;
    font-size: 12px;
    font-weight: 100;
}.prev{
    border: 1px solid rgba(255, 255, 255, 0.13);
    background-color: rgb(0, 0, 0) !important;
}

@media screen and (max-width: 1100px){
    .fixed-section{
        position:relative;
        width: auto;
        display: flex;
        justify-content: center;
    }.main-section-con{
        width: unset;
        background-color: black;
    }.whoisJohn{
        width: unset;
    }.contact-form{
        max-width: unset;
    }

    .whoisJohn,.contact-form{
        margin-left: 60px;
        margin-right: 60px;
    }#john-image{
        margin: unset;
        margin-left: 25px;
    }.mobile-hi{
        display: block;
    }
}

@media screen and (max-width: 750px){
    /* .main-section{
        width: unset;
        margin: 10px;
    } */
    .song-card-con{
        padding: unset;
    }.song-details,.score-parts{
        font-size: 12px;
    }.whoisJohn p,.song-title{
        font-size: 14px;
    }.pause-and-play{
        height: 35px;
        width: 35px;
    }.social-links img{
        opacity: 1;
    }
    .lyricsObj{
        align-self: flex-start;
    }
}

@media screen and (max-width: 550px){
    .whoisJohn,.contact-form{
        margin-left: 35px;
        margin-right: 35px;
    }
     /* .main-section{
        margin: 35px;
    } */
    .single-song-card, .single-song-card > div {
        gap: 10px;
    }.sub-heading{
        font-size: 30px;
    }.mobile-hi{
        font-size: 150px;
        left: 45px;
    }.download-box{
        width: 100%;
        margin: 0px;
        border-radius: 0px;
        position: absolute;

    }.top-download-box{
        border-radius: 0px;
        min-height: calc(100vh - 60px);
    }.download-text{
        font-size: 12px;
    }.social-links{
        bottom: 80px;
    }
}
@media screen and (max-width: 425px){
    .display-score{
        display: flex !important;
    }.card-download-parent{
        position: relative;
    }.whoisJohn,.contact-form{
        margin-left: 30px;
        margin-right: 30px;
    } 
    /* .main-section{
        margin: 30px;
    } */
    .name-and-mail{
        flex-direction: column;
        gap: unset;
    }.title-con{
        font-size: 65px;
        line-height: 70px;
    }
}
@media screen and (max-width: 400px){
    .song-title{
        max-width: 140px;
    }
}
@media screen and (max-width: 346px){
    .song-title{
        max-width: 108px;
    }
}