*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    color: white;
    font-family: 'Open Sans', sans-serif;
}

body{
    background-color: black;
    overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
/* Track */

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
}
/* Handle */

::-webkit-scrollbar-thumb {
	background: #525252;
}
/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
	background: #2e2e2e;
}